import { Box } from "@mui/material"
import { SearchBar } from "../_SearchBar"
import { WebPoppingBar } from "./_WebPoppingBar"
import { ProductList } from "../../Product/ProductList"

export const WebSideBar = () => {
    return (
      <Box display="flex" flexDirection={"column"} width={"25vw"}>
          { <WebPoppingBar /> }
            <Box sx={{ height: "100%", borderRadius: 2, padding: 1, boxShadow: '1px 0 5px -1px #88888850', backgroundColor: "white", marginTop: 2,
                      position: "relative"}}>
                <SearchBar />
                <ProductList view="web" />
            </Box>
      </Box>
    )
}