import React, { useEffect, useState } from 'react';
import { Box, Grid, ImageList, useMediaQuery, useTheme } from '@mui/material';
import { Container } from '@mui/system';
import { SpacePickerService } from '../services/space-picker.service';
import { SpacesList } from '../components/SpacesList/SpacesList';

import { useDispatch, useSelector } from "react-redux";
import { FullState } from '../../../redux/rootReducer';
import { AddSpaceItem } from '../components/SpacesList/AddSpaceItem';
import spacePickerImage from "../../../assets/visualizer/space_picker_image.png"
import { EventsService } from '../../../common/services/events.service';
import { Event, EventTypes, SpaceListPageViewedEvent } from '../../../common/models/events/event.model';
import { Loader } from '../../Visualizer/components/Loader/Loader';


export function SpacesGallery() {
  const predefinedSpaces = useSelector((state: FullState) => state.spacesPicker.predefinedSpaces)
  const theme = useTheme();
  const [spacePickerService] = useState<SpacePickerService>(new SpacePickerService())
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  
  useEffect(() => {
    init()
  }, [])

  const init = async() => {
    
    const spaces = await spacePickerService.getPredefinedSpaces()
    EventsService.push(new Event(EventTypes.SpaceListPageViewedEvent, new SpaceListPageViewedEvent(spaces)))
  }

  return (
    <Container maxWidth="lg" >
      <Grid container>
        <Grid item md={4} xs={12}>
          {isXS && (
            <ImageList variant="masonry" cols={1}>
              {/* First item that handles adding space photo */}
              <AddSpaceItem />
            </ImageList>
          )}
            {!isXS && (
              <AddSpaceItem />
            )}
        </Grid>
        {!isXS && <Grid item md={8} xs={12} display={"flex"} justifyContent={"flex-end"} >
          <img src={spacePickerImage} style={{height: "300px", marginTop: "-60px"}}/>
        </Grid>}
        <Grid item md={12} xs={12}>
          {/* List of spaces */}
          <SpacesList
            suggested={predefinedSpaces}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

